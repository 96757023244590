import Vue from 'vue';
import { MediaMixin } from '~/plugins/layoutable/media';
import { MainStoreMixin } from '~/mixins/business/main';
import { SetCityMixin } from '~/mixins/business/setCity';
import { StaticDataMixin } from '~/mixins/staticData';
import isEqual from 'lodash.isequal';
import { getBucketData } from '~/api/getBucketData';
import { uri } from '~/apollo-api/client/http-link';

export default Vue.extend({
  mixins: [MediaMixin, MainStoreMixin, SetCityMixin, StaticDataMixin],
  computed: {
    isCabinet() {
      return this.$route.name.includes('cabinet') || this.$route.name == 'signin' || this.$route.name == 'signup';
    },
  },

  async created() {
    if (process.client) {
      await this.getDefaultFiasCity();
      await this.getPoints();
      await this.getCustomCities();
      await this.getDescCatalogList();
      await this.setCity();
    }
  },
  mounted() {
    if (process.client) {
      document.addEventListener('visibilitychange', this.visibilityChanged);
      window.$eventManager.on('updateTokens', this.updateTokens);
      window.$eventManager.on('logout', this.onLogout);

      const cleanCity = this.fiasCities.find((city) =>
        this.city && this.city.name ? city.name == this.city.name : city.name == this.city.city,
      );
      if (cleanCity) {
        this.$store.dispatch('checkout/updateCity', cleanCity);
        this.$store.dispatch('deliveryMap/updateDeliveryMapCity', cleanCity);
        this.$store.commit('deliveryMap/updateStoredCity', cleanCity);
      }
    }
  },
  beforeDestroy() {
    if (process.client) {
      document.removeEventListener('visibilitychange', this.visibilityChanged);
      window.$eventManager.off('updateTokens', this.updateTokens);
      window.$eventManager.off('logout', this.onLogout);
    }
  },
  methods: {
    updateTokens(payload) {
      this.$store.commit('user/setTokens', payload);
    },
    onLogout() {
      if (process.client) {
        this.$store.dispatch('user/logOut');
        this.$store.dispatch('main/cleanProductFavoriteStatus');
        this.$store.dispatch('viewedProducts/remove');
        this.$router.push({ name: 'signin' });
      }
    },
    async getDefaultFiasCity() {
      const url = `https://storage.yandexcloud.net/psb-pvz-bucket/${
        !uri.includes('dev') ? 'defaultPickpoint.json' : 'defaultPickpointDev'
      }`;
      const defaultFiasCity = await getBucketData(url).then((data) => {
        const currentDate = Math.ceil(new Date().getTime() / 1000);
        return data.checkoutFias
          .map((city) => ({
            ...city,
            name: city.city,
            deliveryPoints: [city.kePoint],
            points: city.points.filter((point) => {
              if (point.dateOpened) {
                return currentDate > point.dateOpened;
              }
              return true;
            }),
          }))
          .filter((city) => city.points.length > 0);
      });
      if (defaultFiasCity.length) {
        this.$store.dispatch('checkout/updateDefaultCity', defaultFiasCity[0]);
      }
    },
    visibilityChanged() {
      if (!document.hidden) {
        const cartListFromLocalStorage = JSON.parse(localStorage.getItem('cart')).cart.list;
        if (!isEqual(this.$store.state.cart.list, cartListFromLocalStorage)) {
          this.$store.dispatch('cart/update', cartListFromLocalStorage);
        }
      }
    },
  },
});
