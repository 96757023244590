import Vue from 'vue';
import { AuthenticatedMixin } from '~/mixins/authenticated';

export const HeaderActionsMixin = Vue.extend({
  mixins: [AuthenticatedMixin],
  computed: {
    username() {
      return this.$store.state && this.$store.state.user && this.accessToken
        ? this.$store.state.user?.customer?.contacts?.firstName || 'Кабинет'
        : 'Войти';
    },
  },
  methods: {
    onCabinetClick() {
      if (this.accessToken) {
        this.$router.push({ name: 'cabinet' });
      } else {
        if (this.$route.name === 'signin') {
          return;
        }
        this.$router.push({ name: 'signin' });
      }
    },
  },
});
