<template lang="pug">
link-ui(:to="{name: 'cart'}" v-click-outside="onClose").ds-popup-add-cart
  //- button-favorite.popup-button-favorite(:active="propsData.added" disabled)
  h4.ds-popup-add-cart-title Товар добавлен в корзину.&nbsp;
    span.link-go-to Перейти в корзину
  </template>

<script>
import { AddCartMixin } from '~/mixins/business/addCart';
import { SelectedSkuMixin } from '~/mixins/business/selectedSku';
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';

import { format } from '~/filters/currency';

import ButtonUi from '~/components/common/button.vue';
import LinkUi from '~/components/common/link.vue';

const FADEOUT_DELAY = 2000;

export default {
  components: {
    ButtonUi,
    LinkUi,
  },

  directives: {
    ClickOutside,
  },
  mixins: [AddCartMixin, SelectedSkuMixin],
  inject: ['media'],
  props: {
    propsData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('cart', ['getList']),
    countInCart() {
      return this.getList.find((product) => product.sku.id === this.propsData.sku.id)?.amount || this.propsData.count;
    },
    totalPrice() {
      if (!this.propsData.count || !this.propsData.sku) {
        return 0;
      }
      return this.countInCart * this.propsData.sku.sellPrice;
    },
    photo() {
      return this.propsData.photo || this.propsData.sku.photo.link.high;
    },
    characteristics() {
      if (!this.propsData.sku.characteristicValues) {
        return '';
      }
      return this.propsData.sku.characteristicValues
        .map((value) => `${value.characteristic.title}: ${value.title}`)
        .join(', ');
    },
  },
  mounted() {
    this.fadeoutTimer = setTimeout(() => {
      this.$emit('close');
    }, FADEOUT_DELAY);
  },
  beforeDestroy() {
    if (this.fadeoutTimer) {
      clearTimeout(this.fadeoutTimer);
    }
  },
  methods: {
    formatPrice(price) {
      return format(price);
    },
    onClose() {
      this.$emit('close');
    },
    async addAgain() {
      if (this.countInCart >= this.propsData.sku.availableAmount) {
        return;
      }
      await this.addToCart(this.propsData.product, this.propsData.sku, 1, 'popup');
    },
  },
};
</script>

<style lang="stylus">
.ds-popup-add-cart
  padding 12px 12px 12px
  display flex
  border-radius: 8px

  +Media(Laptop, Desktop, DesktopMax)
    width 360px
    padding-bottom 0px

  .ds-popup-add-cart-title
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom 12px
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    +Media(Mobile, Tablet)
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

  .link-go-to
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color #EA5616
</style>
