
import Vue, { PropType } from 'vue';
import ClickOutside from 'vue-click-outside';

import { FavoriteQueryInput } from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

import { SORT_DATA, DEFAULT_SORT_VALUE } from '~/modules/favorite/constants';

export default Vue.extend({
  directives: {
    ClickOutside,
  },
  components: {
    ButtonUi,
    IconUi,
  },
  props: {
    queryInput: {
      type: Object as PropType<FavoriteQueryInput>,
      required: true,
    },
  },
  data() {
    return {
      sortData: SORT_DATA,
      value: DEFAULT_SORT_VALUE,
      isOpen: false,
    };
  },
  created() {
    this.setInitialValue();
  },
  methods: {
    setInitialValue() {
      if (this.queryInput.sort && this.queryInput.sort !== this.value) {
        this.value = Object.keys(this.sortData).includes(this.queryInput.sort)
          ? this.queryInput.sort
          : DEFAULT_SORT_VALUE;
      }
    },
    onSelect(key) {
      if (this.value === key) {
        this.onClose();
        return;
      }
      this.value = key;
      this.$emit('update-query-input', { sort: key });
      this.onClose();
    },
    changeIndex(toggle) {
      const searchBlock = document.querySelector('#header-search-block') as HTMLElement;
      const headerBlock = document.querySelector('#main-header') as HTMLElement;
      if (toggle && searchBlock && headerBlock) {
        searchBlock.style.zIndex = '0';
        headerBlock.style.zIndex = '0';
      }
      if (!toggle && searchBlock && headerBlock && searchBlock.style.zIndex !== '4') {
        searchBlock.style.zIndex = '3';
        headerBlock.style.zIndex = '3';
      }
    },
    onToggle() {
      this.isOpen = !this.isOpen;
    },
    onClose() {
      this.isOpen = false;
      this.changeIndex(this.isOpen);
    },
  },
});
