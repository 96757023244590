<template lang="pug">
.ds-catalog-mobile-container
  .ds-catalog-mobile
    template(v-if="isDeep")
      div.category-nav-button.parent-category(@click="onClickStack(-1)")
        icon-ui.category-nav-icon(name="navigation/back_sharp" size="24" )
        .default {{ currentCategory.title }}
    ul.categories.ui-wrapper(:class="{ 'first-catalog-page': !isDeep }")
      li.category-item(
        v-if="isDeep"
        :key="currentCategory.id"
        @click="goTo(currentCategory)"
      )
        button-ui.category-item-button {{ currentCategory.title }}
          icon-ui.category-item-icon(
            v-if="isDeep"
            name="navigation/chevron-right_sharp"
            width="12"
            height="16"
            slot="after"
          )
      li.category-item(v-for="category in currentCategories" :key="category.id" :class="{ 'first-catalog-page-item': !isDeep, 'promo-item': category.isPromo }" @click="onClickDeeper(category)")
        .category-page-icon(v-if="!isDeep")
          icon-ui(
            :name="returnIcon(category.title)"
            width="24"
            height="24"
          )
        button-ui.category-item-button {{ category.title }}
          icon-ui.category-item-icon(
            v-if="isDeep"
            name="navigation/chevron-right_sharp"
            width="12"
            height="16"
            slot="after"
          )
</template>

<script>
// import { buildCategoryUrl } from './catalog';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';

import { mapState, mapGetters } from 'vuex';
import { buildCategoryUrl, catalogIcons } from '~/components/header/catalog/catalog-categories/catalog';

export default {
  components: { IconUi, ButtonUi },

  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
    isCatalogOpen: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    stack: [],
  }),

  computed: {
    ...mapGetters('catalog', ['sortedCategories']),
    ...mapState('catalog', ['promoCategories']),
    categories() {
      return [
        ...this.promoCategories.map((_) => ({ ..._, isPromo: true })),
        ...this.sortedCategories.map((_) => ({ ..._, isPromo: false })),
      ];
    },
    currentCategories() {
      let res = this.categories;

      this.stack.forEach((stackItem) => {
        res = res.find((category) => category.id === stackItem.id).children || [];
      });
      return res;
    },
    currentCategory() {
      return this.stack.length ? this.stack[this.stack.length - 1] : undefined;
    },
    isDeep() {
      return Boolean(this.stack.length);
    },
  },

  watch: {
    stack() {
      if (!this.stack.length && this.$route.query.isDeep === 'true') {
        this.$router.replace({
          name: 'mobile-catalog',
          query: {
            isDeep: this.isDeep,
          },
        });
      }
    },
  },

  mounted() {
    if (!this.stack.length && this.$route.query.isDeep === 'true') {
      this.$router.replace({
        name: 'mobile-catalog',
        query: {
          isDeep: this.isDeep,
        },
      });
    }
  },

  methods: {
    onClose() {
      this.$emit('close');
    },
    onClickDeeper(category) {
      if (category.isPromo) {
        this.onClickPromo(category);
        return;
      }
      if (!category.children || !category.children.length) {
        this.goTo(category);
      } else if (!this.isDeep) {
        this.stack.push(category);
        this.$router.replace({
          name: 'mobile-catalog',
          query: {
            isDeep: this.isDeep,
          },
        });
      } else {
        this.stack.push(category);
      }
    },
    returnIcon(title) {
      return catalogIcons.get(title) ? catalogIcons.get(title) : 'category/sale';
    },
    onClickPromo(promo) {
      console.log(promo);
      if (promo.deepLink.includes('https://xn--80abntiqkep.xn--p1ai')) {
        let newUrl = promo.deepLink.replace('https://xn--80abntiqkep.xn--p1ai', 'https://mobile.psbmarket.su');
        window.open(newUrl).focus();
        return;
      }
      if (promo.deepLink.includes('https://псбмаркет.рф')) {
        let newUrl = promo.deepLink.replace('https://псбмаркет.рф', 'https://mobile.psbmarket.su');
        window.open(newUrl).focus();
        return;
      }
      window.open(promo.deepLink).focus();
    },
    onClickStack(index) {
      this.stack = this.stack.slice(0, index);
      if (!this.stack.length) {
        this.$router.replace({
          name: 'mobile-catalog',
          query: {
            isDeep: false,
          },
        });
      }
    },
    goTo(category) {
      this.$router.push(buildCategoryUrl(category));
      this.onClose();
    },
  },
};
</script>

<style lang="stylus" scoped>
// .slide-enter-active
//   transition all .2s ease
// .slide-leave-active
//   transition all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0)
// .slide-enter, .slide-leave-to
//   transform translateX(-100%)
//   opacity 0

.ds-catalog-mobile
  .categories
    margin-top:16px
  .category-nav-button
    display: flex;
    width: 100%;
    padding: 6px 0px 22px 0px;
    align-items: center;
    gap: 10px;
    color: #1F1F26;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    position relative
    font-weight: 600;
    line-height: 20px
    background: #FFF;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.10);
  .parent-category
    Text(BodyL Semibold Short)
    padding: 6px 16px 22px 16px;
    .default
      flex-grow: 1;
  .category-nav-icon
    position: absolute;
    left: 8px;
    right: 0;
  .category-item
    width 100%
  .category-item-button
    Text(BodyL Short)
    min-height 44px
    width 100%
    padding 12px 16px 12px 0px
    display flex
    justify-content space-between
    align-items center
    text-align left
    color #4D4E59
    &.all-items
      Text(Semibold)
    &.promo-button
      font-weight 600
  .category-page-icon
    width: 36px;
    height: 36px;
    background: #2C2D84
    display: flex
    align-items: center
    justify-content: center
    border-radius: 100%
  .category-item-icon
    fill:#C2C4CC
.first-catalog-page
  display: grid;
  margin-top: 0px !important
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  gap: 16px;

  .first-catalog-page-item
    border-radius: 8px;
    background: #F2F4F7;
    display: flex;
    height: 108px;
    padding: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    &.promo-item
      background: #DFE1F7;
  .category-item-button
    padding: 0
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    min-height: 0
    color #1F1F26
</style>
