
import Vue, { PropType } from 'vue';

import {
  FilterType,
  MakeSearchFilters_makeSearch_category,
  MakeSearchFilters_makeSearch_categoryTree,
  MakeSearchFilters_makeSearch_facets,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

import SearchFilterCategoryDs from '~/modules/search/components/filters/search-filter-category.vue';
import SearchFilterItemDs, {
  getComponentByFilterType,
} from '~/modules/search/components/filters/search-filter-item.vue';

export default Vue.extend({
  components: {
    ButtonUi,
    IconUi,
    SearchFilterCategoryDs,
    SearchFilterItemDs,
  },
  inject: ['media'],
  props: {
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    facets: {
      type: Array as PropType<MakeSearchFilters_makeSearch_facets[] | null>,
      default: null,
    },
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
    categoryTree: {
      type: Array as PropType<MakeSearchFilters_makeSearch_categoryTree[]>,
      required: true,
    },
    queryInput: {
      type: Object as PropType<MakeSearchQueryInput>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      filters: null,
    };
  },

  methods: {
    hasFilterComponent(type: FilterType) {
      return getComponentByFilterType(type);
    },
    setFilter(filters) {
      this.filters = filters;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!this.media.isMobileOrTablet) {
        this.$emit('update-query-input', this.filters);
      }
    },
    submitFilters() {
      this.$emit('update-query-input', this.filters);
      this.isOpen = false;
    },
    changeIndex(toggle) {
      const searchBlock = document.querySelector('#header-search-block') as HTMLElement;
      const headerBlock = document.querySelector('#main-header') as HTMLElement;
      if (toggle) {
        if (searchBlock && headerBlock) {
          searchBlock.style.zIndex = '0';
          headerBlock.style.zIndex = '0';
          return;
        }
      } else {
        if (searchBlock && headerBlock) {
          searchBlock.style.zIndex = '20';
          headerBlock.style.zIndex = '20';
          return;
        }
      }
    },
    onToggle() {
      this.isOpen = !this.isOpen;
      this.changeIndex(this.isOpen);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // if (this.media.isMobileOrTablet) {
      //   const headerContainer = document.getElementsByClassName('ds-header-container')[0] as HTMLElement;
      //   headerContainer.style.zIndex = '0';
      // }
    },
    onClose() {
      this.isOpen = false;
      this.changeIndex(this.isOpen);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // if (this.media.isMobileOrTablet) {
      //   const headerContainer = document.getElementsByClassName('ds-header-container')[0] as HTMLElement;
      //   headerContainer.style.zIndex = '100';
      // }
    },
  },
});
