import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '~/pages';
import CartPage from '~/pages/cart';
import FaqPage from '~/pages/faq';
import ProductPage from '~/pages/product/index';
import SigninPage from '~/pages/signin';
import SignupPage from '~/pages/signup';

import CabinetPage from '~/pages/cabinet';
import CabinetOrdersPage from '~/pages/cabinet/orders/orders';
import CabinetOrderPage from '~/pages/cabinet/orders/order';
import CabinetProfileSettingsPage from '~/pages/cabinet/profile-settings';
import CabinetFeedbacksPage from '~/pages/cabinet/feedbacks';
import DeliveryPointsPage from '~/pages/delivery-points';

import CheckoutPage from '~/pages/checkout';

import SearchPage from '~/pages/search';
import CatalogPage from '~/pages/catalog';
import OfferPage from '~/pages/offer';
import FavoritePage from '~/pages/favorite';

import AboutPage from '~/pages/about';
import AboutContactsPage from '~/pages/about/children/contacts';
import AboutCompanyPage from '~/pages/about/children/company';

import TermsOfUsePage from '~/pages/termsOfUse';
import PrivacyPolicy from '~/pages/privacy-policy';
import MobileCatalog from '~/pages/mobile-catalog';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/product/:name(.*?-)?:id(\\d+)',
    name: 'product',
    component: ProductPage,
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqPage,
  },
  {
    path: '/cabinet',
    name: 'cabinet',
    component: CabinetPage,
    redirect: { name: 'cabinet-orders' },
    children: [
      {
        path: 'order/:id',
        name: 'cabinet-order',
        component: CabinetOrderPage,
        props: true,
      },
      {
        path: 'orders',
        name: 'cabinet-orders',
        component: CabinetOrdersPage,
      },
      {
        path: 'settings',
        name: 'cabinet-settings',
        component: CabinetProfileSettingsPage,
      },
      {
        path: 'feedbacks',
        name: 'cabinet-feedbacks',
        component: CabinetFeedbacksPage,
      },
    ],
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
    redirect: { name: 'about-company' },
    children: [
      {
        path: 'company',
        name: 'about-company',
        component: AboutCompanyPage,
      },
      {
        path: 'contacts',
        name: 'about-contacts',
        component: AboutContactsPage,
      },
    ],
  },
  {
    path: '/termsofuse',
    name: 'termsofuse',
    component: TermsOfUsePage,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/mobile-catalog',
    name: 'mobile-catalog',
    component: MobileCatalog,
  },
  {
    path: '/signin',
    name: 'signin',
    component: SigninPage,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutPage,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
  },
  {
    path: '/catalog/:dshopCategoryId?',
    name: 'catalog',
    component: CatalogPage,
    props: true,
  },
  {
    path: '/offer/:offerID?',
    name: 'offer',
    component: OfferPage,
    props: true,
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: FavoritePage,
  },
  {
    path: '/delivery-points',
    name: 'delivery-points',
    component: DeliveryPointsPage,
  },
];

export function createRouter() {
  return new Router({
    mode: 'history',

    scrollBehavior(to, from, position) {
      if (position) {
        setTimeout(() => {
          window.scrollTo(0, position.y);
        }, 100);
      } else {
        return { x: 0, y: 0 };
      }
    },
    routes,
  });
}
