import {
  DshopMain_dshopMain_categories,
  DshopMain_dshopMain_categories_children_children,
  DshopMain_dshopMain_categories_image,
} from '~/apollo-api/types';

interface Category extends Omit<DshopMain_dshopMain_categories, 'image' | 'children'> {
  image?: DshopMain_dshopMain_categories_image | null;
  children?: DshopMain_dshopMain_categories_children_children[];
}

const CATALOG_PATH = '/catalog';

export const buildCategoryUrl = (category: Category) => `${CATALOG_PATH}/${category.id}`;

export const catalogIcons = new Map([
  ['Электроника', 'category/phone'],
  ['Бытовая техника', 'category/vacuum'],
  ['Бытовая химия и личная гигиена', 'category/soap'],
  ['Товары для дома', 'category/couch'],
  ['Автотовары', 'category/car'],
  ['Строительство и ремонт', 'category/tools'],
  ['Одежда', 'category/clothes'],
  ['Обувь', 'category/shoes'],
  ['Аксессуары', 'category/bag'],
  ['Красота', 'category/lipstick'],
  ['Детские товары', 'category/toy'],
  ['Спорт и отдых', 'category/sport'],
  ['Дача, сад и огород', 'category/grill'],
  ['Зоотовары', 'category/paw'],
  ['Канцтовары', 'category/pencil'],
  ['Хобби и творчество', 'category/edit'],
  ['Книги', 'category/book'],
  ['Здоровье', 'category/heart'],
  ['Товары для взрослых', 'category/adult'],
  ['Доставка в ЛНР и ДНР', 'category/location'],
]);
