<template lang="pug">
link-ui(:to="{name: 'favorite'}" v-click-outside="onClose").ds-popup-add-favorite
  //- button-favorite.popup-button-favorite(:active="propsData.added" disabled)
  h4(v-if="propsData && propsData.added").ds-popup-add-favorite-title Товар добавлен в избранное.&nbsp;
    span.link-go-to Перейти к списку
  h4(v-else).ds-popup-add-favorite-title Товар удален из избранного.&nbsp;
    span.link-go-to Перейти к списку
</template>

<script>
import ClickOutside from 'vue-click-outside';
import LinkUi from '~/components/common/link';
const FADEOUT_DELAY = 2000;

export default {
  directives: {
    ClickOutside,
  },
  components: { LinkUi },
  inject: ['media'],
  props: {
    propsData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.fadeoutTimer = setTimeout(() => {
      this.$emit('close');
    }, FADEOUT_DELAY);
  },
  beforeDestroy() {
    if (this.fadeoutTimer) {
      clearTimeout(this.fadeoutTimer);
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-popup-add-favorite
  padding 12px 12px 12px
  display flex
  border-radius: 8px
  +Media(Laptop, Desktop, DesktopMax)
    width 360px
    padding-bottom 0px

  .ds-popup-add-favorite-title
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom 13px
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    +Media(Mobile, Tablet)
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-align center
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

  .link-go-to
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color #EA5616
</style>
