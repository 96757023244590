<template lang="pug">
header(id='main-header-container' class="ds-header-container")
  subheader-ds(v-if="!media.isMobileOrTablet")
  .header-container( :class="isShowSearch ? '' : 'without-search'")
    .ds-header.ds-component
      .mobile-top(v-if="media.isMobileOrTablet && !hideTop" id="main-header")
        .mobile-header-info(id="header-info" v-if="!hideInfoTop")
          logo
          link-ui.mobile-header-info-content(:to="{ name: 'delivery-points' }")
            icon-ui(name="operations/pickup" :size="18" style="margin-right: 4px")
            span Пункты выдачи
      .header-wrapper(:class="{'hide-border': hideBorder}")
        .header-actions(:class="{active: routeName === 'home'}")
          link-ui.action-header(:to="{ name: 'home' }")
            icon-ui(name="mobile/home" size="20")
        .header-actions(:class="{active: routeName === 'mobile-catalog'}")
          link-ui.action-header(:to="{ name: 'mobile-catalog' }")
            icon-ui(name="mobile/apps" size="20")
        client-only
          .header-actions(:class="{active: routeName === 'cart'}")
            link-ui.action-header(:to="{ name: 'cart' }")
              labeled-icon-ds(name="mobile/shopping-cart" size="20" :badge="cartCount") Корзина
        client-only
          .header-actions(:class="{active: routeName === 'favorite'}")
            link-ui.action-header(:to="{ name: 'favorite' }")
              labeled-icon-ds(name="mobile/heart" size="20") Избранное
        .header-actions(:class="{active: isCabinet}")
          button-ui.action-header.username(@click.stop="onCabinetClick")
            labeled-icon-ds(name="mobile/user" size="20") {{ username }}
  .header-categories-container
    header-categories-ds(
      @toggle-catalog="toggleCatalog"
      @more-click="handleMoreClick"
    )

  mobile-menu(
    v-if="media.isMobileOrTablet"
    :is-menu-open="isMenuOpen"
    :is-catalog-open="isCatalogOpen"
    @toggle="toggleCatalog"
    @close="closeCatalog"
    @close-menu="closeMenu"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '~/components/common/logo';
import HeaderCatalogDs from './catalog';
import HeaderCategoriesDs from './header-categories';
import MobileMenu from './mobile-menu';
import { SearchSuggestions } from '~/modules/search-suggestions';
import { HeaderActionsMixin } from '~/mixins/header-actions-mixin';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import LabeledIconDs from '~/components/labeled-icon';
import SubheaderDs from '~/components/subheader';

const NO_SCROLL_CLASS = 'no-scroll-menu';

export default {
  components: {
    SearchSuggestions,
    ButtonUi,
    IconUi,
    LinkUi,
    Logo,
    HeaderCatalogDs,
    HeaderCategoriesDs,
    MobileMenu,
    LabeledIconDs,
    SubheaderDs,
  },

  mixins: [HeaderActionsMixin],

  inject: ['media'],

  data: () => ({
    isMenuOpen: false,
    isCatalogOpen: false,
    isSearchOpen: false,
    isShowCloseIcon: false,
  }),

  computed: {
    ...mapGetters('cart', ['getList']),
    cartCount() {
      return this.getList.reduce((sum, item) => sum + item.amount, 0);
    },
    menuIcon() {
      return this.isMenuOpen ? 'navigation/dismiss_sharp' : 'navigation/menu_sharp';
    },
    routeName() {
      return this.$route.name;
    },
    isCabinet() {
      return this.$route.name.includes('cabinet') || this.$route.name == 'signin' || this.$route.name == 'signup';
    },
    hideBorder() {
      return this.$route.name.includes('product') || this.$route.name.includes('cart');
    },
    hideInfoTop() {
      return this.$route.name == 'mobile-catalog';
    },
    hideTop() {
      return !!(
        this.$route.name.includes('cart') ||
        this.isCabinet ||
        this.$route.name.includes('checkout') ||
        this.$route.name == 'favorite' ||
        (this.$route.name === 'mobile-catalog' && this.$route.query.isDeep === 'true')
      );
    },
    isShowSearch() {
      if (this.media.isMobileOrTablet) {
        return this.$route.name !== 'signup';
      }
      return true;
    },
    showSearch() {
      return (
        this.$route.name == 'home' ||
        this.$route.name == 'search' ||
        this.$route.name == 'catalog' ||
        this.$route.name == 'offer' ||
        this.$route.name == 'delivery-points' ||
        (this.$route.name == 'mobile-catalog' &&
          !JSON.parse(this.$route.query.isDeep ? this.$route.query.isDeep : 'false'))
      );
    },
  },
  watch: {
    $route() {
      if (document) {
        this.closeMenu();
      }
    },
  },

  mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.scrollHandler);
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.classList.toggle(NO_SCROLL_CLASS);
      const outTop = document.getElementById('top-info');
      if (outTop) {
        if (document.getElementById('__nuxt').style.marginTop == '-27px') {
          document.getElementById('__nuxt').style.marginTop = '0px';
        } else {
          document.getElementById('__nuxt').style.marginTop = '-27px';
        }
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      this.closeCatalog();
      document.body.classList.remove(NO_SCROLL_CLASS);
    },
    toggleCatalog() {
      this.isCatalogOpen = !this.isCatalogOpen;
      this.isShowCloseIcon = false;
    },
    showTop() {
      return true;
    },
    closeCatalog() {
      this.isCatalogOpen = false;
      this.isShowCloseIcon = false;
    },
    handleMoreClick() {
      this.isCatalogOpen = true;
      this.isShowCloseIcon = true;
    },
    // scrollHandler() {
    //   let header = document.getElementById('main-header');
    //   let hidePart = header.querySelector('#header-info');
    //   if (window.scrollY > 0) {
    //     header.classList.add('fixed-header');
    //     if (this.media.isMobileOrTablet && !this.hideInfoTop && !this.hideTop) {
    //       hidePart.style.height = '0px';
    //       hidePart.style.opacity = 0;
    //       hidePart.style.padding = '0px';
    //       hidePart.style.transition = 'all .3s';
    //     }
    //   } else {
    //     header.classList.remove('fixed-header');
    //     if (this.media.isMobileOrTablet && !this.hideInfoTop && !this.hideTop) {
    //       hidePart.style.height = '46px';
    //       hidePart.style.opacity = 1;
    //       hidePart.style.padding = '12px 0px 16px 0px';
    //       hidePart.style.transition = 'all .3s';
    //     }
    //   }
    // },
  },
};
</script>

<style lang="stylus">
.ds-header-container
  background-color: #fff

  .header-categories-container
    display none

    +Media(Laptop, Desktop, DesktopMax)
      display block
.mobile-top
  display: flex
  flex-direction: column
  width: 100%
  padding: 0px 16px
  background-color: #fff
  .mobile-header-info
    display: flex;
    padding: 12px 0px 8px 0px;
    align-items: center;
    justify-content: space-between

    .mobile-header-info-content
      display: flex;
      align-items: center;
      color: #8B8E99;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
.header-container
  background-color White()
  padding 8px 0
  display flex
  align-items stretch
  position relative

  +Media(DesktopMax, Desktop)
    min-height 70px

  +Media(Laptop)
    min-height 60px

  +Media(Mobile, Tablet)
    padding 0
    min-height auto
    height auto
    display flex
    flex-direction column

  .ui-wrapper
    display flex
    align-items stretch

    +Media(Mobile, Tablet)
      padding 0

  .ds-header-logo
    +Media(Desktop, DesktopMax)
      margin-right 32px

    +Media(Mobile, Tablet, Laptop)
      margin-right 24px

.ds-header
  position relative
  width 100%
  display flex
  align-items stretch


  .header-wrapper
    display flex
    align-items center

    +Media(Mobile, Tablet)
      padding 0 16px
      width 100%
      bottom 0
      z-index 1
      background-color: #fff
      position fixed !important
      border-top: 0.3px solid #D0D2D9;
      height: 48px
      justify-content: space-between

    &.hide-border
      +Media(Mobile, Tablet)
        border-top: none;
  .header-menu
    display none
    margin-right 12px

    +Media(Mobile, Tablet)
      display block
      margin-right 0

    svg path
      fill BlueBrand() !important

  .header-catalog
    margin-right 12px
    display none

    +Media(Laptop, Desktop, DesktopMax)
      display block

  .header-search
    width 100%

    +Media(Laptop, Desktop, DesktopMax)
      margin-right 40px
      display block
    // +Media(Mobile, Tablet)
    //   padding-bottom 8px
    //   position sticky
    //   top 0
    //   background-color White()
    //   z-index 100

  .header-actions
    HeaderAction()
    .action-header
      width: 48px
      display: flex
      align-items: center
      justify-content: center
      &.username .label
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        max-width 75px
.fixed-header
  position fixed
  top 0px
  width 100%
  z-index 100
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.10);

.without-search
  +Media(Mobile, Tablet)
    min-height 49px !important
    height 49px !important
</style>
