<template lang="pug">
.signup-page
  .ui-wrapper
    .container
      .form
        .form-container
          client-only
            signin-tab-ds(:is-signup="true")
</template>

<script>
import SigninTabDs from '~/components/sign/signin-tab';
import Cookies from 'js-cookie';

export default {
  components: {
    SigninTabDs,
  },
  provide() {
    return {
      initPhone: '',
    };
  },
  middleware({ store, redirect }) {
    const tokens = Cookies.get('auth');
    const isAuth = tokens ? JSON.parse(tokens).accessToken : false;
    if (isAuth) {
      return redirect({ name: 'cabinet' });
    }
  },
  mounted() {
    this.$eventManager.on('registration-end', this.goHome);
    this.$eventManager.on('entry-end', this.goCabinet);
  },
  beforeDestroy() {
    this.$eventManager.off('registration-end', this.goHome);
    this.$eventManager.off('entry-end', this.goCabinet);
  },
  methods: {
    goToLastPage(defaultPath) {
      const newPath = this.$route.query.path;
      if (newPath && typeof newPath === 'string') {
        this.$router.push(newPath);
      } else {
        this.$router.push({ name: defaultPath });
      }
    },
    goHome() {
      this.goToLastPage('home');
    },
    goCabinet() {
      this.goToLastPage('cabinet');
    },
  },
};
</script>

<style lang="stylus">
.signup-page

  .container
    margin 0 auto

    +Media(DesktopMax)
      width 525px

    +Media(Tablet, Laptop, Desktop)
      width 465px

    +Media(Mobile)
      width 100%

    .form
      position relative

      +Media(Tablet, Laptop, Desktop, DesktopMax)
        border 1px solid Gray(A20)

      +Media(Laptop, Desktop, DesktopMax)
        min-height 536px
      +Media(Tablet)
        min-height 414px

      .form-container
        margin 0 auto
        padding-top 8px

        +Media(DesktopMax)
          width 350px

        +Media(Tablet, Laptop, Desktop)
          width 310px
</style>
