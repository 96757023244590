<template lang="pug">
.ds-favorite-page
  h1.favorite-title Избранное
  favorite-ds(search-type="favorite")
</template>

<script>
import { StaticDataMixin } from '~/mixins/staticData';

import FavoriteDs from '~/modules/favorite/components/index.vue';
import { isEqualArray } from '~/modules/common/equal-array';

export default {
  components: {
    FavoriteDs,
  },
  mixins: [StaticDataMixin],
  computed: {
    sort() {
      return +this.$route.query.sort || 0;
    },
  },

  beforeDestroy() {
    this.$eventManager.off('updateQuery', this.onUpdateQuery);
  },

  mounted() {
    this.$eventManager.on('updateQuery', this.onUpdateQuery);
  },

  methods: {
    onUpdateQuery(payload) {
      const name = payload.name === 'dshopFilterCategories' ? 'cat' : payload.name;
      const value = payload.value;
      if (isEqualArray(this.$route.query[name], value)) {
        // does not update same value
        return;
      }
      const query = { ...this.$route.query };
      if (value === undefined) {
        delete query[name];
      } else {
        query[name] = value;
      }
      this.$router.push({
        query,
        params: { toPosition: { y: Math.min(window.scrollY, this.$refs.searchResult.$refs.content.offsetTop) } },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-favorite-page
  height: 100%
  flex-grow 1
  display: flex
  flex-direction: column
  .favorite-title
    Text(MobileTitle)
</style>
