<template lang="pug">
.ds-error-no-content
  img.no-content-image(src="/images/empty_cart.png")
  .no-content-title Мы не нашли то, что вы искали
  .no-content-content {{error && error.message || "Возможно, в названии товара ошибка или у нас пока его нет"}}
  button-ui(@click.stop="$router.push({ name: 'home' })") На главную
</template>

<script>
import ButtonUi from '~/components/common/button';
import LinkUi from '~/components/common/link';

export default {
  components: {
    ButtonUi,
    LinkUi,
  },
  props: {
    error: {
      default: () => ({}),
    },
  },
  head: {
    link: [
      {
        rel: 'preload',
        as: 'image',
        href: '/images/empty_cart.png',
      },
    ],
  },
};
</script>

<style lang="stylus" scoped>
.ds-error-no-content
  max-width 450px
  margin 42px auto
  text-align center
  .no-content-image
    width 100%
    +Media(Laptop, Desktop, DesktopMax)
      margin-bottom 32px
    +Media(Tablet, Mobile)
      margin-bottom 24px
  .no-content-title
    margin-bottom 8px
    +Media(Laptop, Desktop, DesktopMax)
      Text(TitleM)
    +Media(Tablet, Mobile)
      Text(TitleS)
  .no-content-content
    Text(BodyM Long)
  .no-content-footer
    Text(BodyM Long)
    color Gray(D8)
  .ui-button
    +Media(Laptop, Desktop, DesktopMax)
      Button(Medium Primary)
      margin 28px 0 48px
    +Media(Tablet, Mobile)
      Button(Large Primary)
      margin 28px 0 48px
  .ui-link
    Text(Link)
    color inherit
    text-decoration underline
</style>
