<template lang="pug">
.app(:class="`page-${$route.name}`")
  main(class="page-content")
    nuxt
  footer-ds(v-if="isCabinet")

  popups-container-ds
</template>

<script>
import Common from './common';
import FooterDs from '~/components/footer';
import { PRODUCTS_SIZE } from '~/modules/offer/data';
import PopupsContainerDs from '~/modules/popups/popups-container';

export default Common.extend({
  name: 'HeaderlessLayout',
  components: {
    PopupsContainerDs,
    FooterDs,
  },
  watch: {
    size: {
      handler(value) {
        if (value) {
          this.getMainData(PRODUCTS_SIZE[value], this.isMobileOrTablet);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (process.client) {
      this.getMainCategories();
    }
  },
});
</script>

<style lang="stylus" scoped>
.app
  display flex
  flex-direction column
  min-height 100vh

  .page-content
    flex-grow 1
    padding: 0px 16px
    display: flex
    flex-direction: column
  +Media(Mobile, Tablet)
    padding-bottom 24px
    &.page-checkout .ds-footer
      display none
</style>
