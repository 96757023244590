<template lang="pug">
.logo
  link-ui(:to="{ name: 'home' }")
    .logo__image
      img(:src=" isMobileHeader ? '/icon.png' : `/images/logo.svg`" :alt="altImage")
</template>

<script>
import LinkUi from '~/components/common/link';
import { titles } from '~/shop-config';

export default {
  components: {
    LinkUi,
  },
  props: {
    isMobileHeader: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    altImage() {
      return titles.default || '';
    },
  },
};
</script>

<style lang="stylus">
.logo
  margin-right 10px
  padding: 0px;

  &__image
    HeaderLogoWrapper()

    img
      HeaderLogoImage()
</style>
