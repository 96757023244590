<template lang="pug">
client-only
  .cart-page
    h1.cart-title Корзина
    .ui-wrapper.cart-content-wrapper(v-if="localCities")
      cart-content
    .ui-wrapper.cart-footer-wrapper
      cart-footer
</template>

<script>
import GuaranteesUi from '~/components/guarantees';
import CartContent from './components/content';
import CartFooter from './components/footer';
import { CartMixin } from '~/mixins/business/cart';
import { StaticDataMixin } from '~/mixins/staticData';

export default {
  name: 'PageCart',

  components: {
    GuaranteesUi,
    CartContent,
    CartFooter,
  },

  mixins: [CartMixin, StaticDataMixin],
  async fetch() {
    await this.getPoints();
  },

  head: {
    link: [
      {
        rel: 'preload',
        as: 'image',
        href: '/images/empty_cart.png',
      },
    ],
  },
};
</script>

<style lang="stylus" scoped>
.cart-page
  display flex
  flex-direction column
  flex-grow: 1

  .cart-content-wrapper
    order 1

  .cart-footer-wrapper
    order 3
.cart-title
  Text(MobileTitle)
</style>
