<template lang="pug">
.ds-footer.ds-component
  .ui-wrapper
    .items-group-menu
      .menu-item(v-for="group in menu" :key="group.id")
        h4.menu-item-title(@click="toggleMenuBlock(group.id)") {{ group.title }}
          icon-ui.menu-item-icon(:name="getIconForMenuBlock(group.id)")
        ul.menu-links(v-show="group.id === openedMenuBlock")
          li.menu-link(v-for="(linkItem, i) in group.links" :key="`linkItem_${i}`")
            link-ui(:to="linkItem.link" :target="linkItem.target ? linkItem.target : ''") {{ linkItem.title }}

    .items-group-contacts
      template(v-if="static.links.socials.length")
        h4.contacts-links-title Мы в социальных сетях
        .contacts-links
          link-ui.contacts-link(v-for="social in static.links.socials" :key="social.link" :to="social.link" target="_blank")
            icon-ui.contacts-icon(:name="social.icon" :size="44")
      h4.contacts-phone-title Нам доверяют
      link-ui.contacts-partners(to="https://novorossiya.gosuslugi.ru/other/psb_market" target="_blank")
        icon-ui(name="social/gosuslugi" :width="124" :height="20")

  .ui-wrapper(v-if="false")
    .items-group-subscribe
      .subscribe-item
        h4.subscribe-title Подписка на новости
        .subscribe-description Узнавайте о новинках, акциях и распродажах первыми
        input-ui.subscribe-input(placeholder="Введите свой email")
          icon-ui(name="navigation/arrow-right_line" slot="after")

  .ui-wrapper
    .items-group-terms
      ul.terms-links
        li.terms-link: link-ui(to="/privacy-policy" target="_blank") Согласие на обработку персональных данных
        li.terms-link: link-ui(to="/termsofuse" target="_blank") Пользовательское соглашение

  .ui-wrapper
    footer.items-group-footer
      .footer-items
        .footer-item {{ static.yearRights }} © {{ static.brand }}. ИНН {{ static.contacts.inn }}. Все права защищены.
      .kazanexpress(v-if="static.isShowKazanExpressTechnologies")
        span Работает на технологиях
        span.kazanexpress-logo KazanExpress
</template>

<script>
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import InputUi from '~/components/common/input';
import { phoneFormatMixin } from '~/mixins/phone';
import { StaticDataMixin } from '~/mixins/staticData';

export default {
  components: {
    LinkUi,
    InputUi,
    IconUi,
  },
  mixins: [phoneFormatMixin, StaticDataMixin],
  data: () => ({
    openedMenuBlock: null,
  }),
  computed: {
    menu() {
      return [
        {
          id: 'help',
          title: 'Пользователям',
          links: [
            // { link: '/faq#how-pay', title: 'Оплата и доставка' },
            // { link: '/faq#return-product', title: 'Правила возврата' },
            { link: '/faq', title: 'Часто задаваемые вопросы' },
            // { link: this.static.support.chat, title: 'Связаться с нами' },
          ],
        },
        {
          id: 'about',
          title: 'О нас',
          links: [
            { link: '/about/company', title: 'О компании' },
            { link: '/about/contacts', title: 'Контакты' },
            { link: 'https://ib2.psbank.ru', title: 'Банк ПСБ', target: '_blank' },
          ],
        },
      ];
    },
  },
  methods: {
    getIconForMenuBlock(block) {
      return block === this.openedMenuBlock ? 'operations/chevron-up_small-line' : 'operations/chevron-down_small-line';
    },
    toggleMenuBlock(block) {
      if (this.openedMenuBlock !== block) {
        this.openedMenuBlock = block;
      } else {
        this.openedMenuBlock = null;
      }
    },
  },
};
</script>

<style lang="stylus">
.ds-footer
  background White()
  border-top 1px solid Divider()
  padding-top 24px
  margin-top 32px
  +Media(Tablet, Mobile)
    margin-top 20px
    padding-top 20px

  .ui-wrapper
    display flex
    justify-content space-between
    flex-wrap wrap

  .items-group-menu
    display flex
    +Media(Tablet, Mobile)
      flex 100% 0 0
      flex-grow 1
      flex-wrap wrap
    .menu-item
      +Media(DesktopMax)
        width 310px
        &:not(:last-child)
          margin-right 40px
      +Media(Desktop)
        width 270px
        &:not(:last-child)
          margin-right 40px
      +Media(Laptop)
        width 276px
        &:not(:last-child)
          margin-right 32px
      +Media(Tablet, Mobile)
        margin-right 0
        flex 100% 0 0
        flex-grow 1
        border-bottom 1px solid Divider()
        text-align center
        cursor pointer
    .menu-item-title
      Text(BodyM Medium Short)
      margin-bottom 8px
      color TextPrimary()
      +Media(Tablet, Mobile)
        margin-bottom 0
        padding 14px 0
        display flex
        align-items center
        justify-content center
    .menu-item-icon
      +Media(Laptop, Desktop, DesktopMax)
        display none
    .menu-links
      +Media(Laptop, Desktop, DesktopMax)
        display block !important
      +Media(Tablet, Mobile)
        margin-bottom 14px
    .menu-link
      padding 6px 0
      .ui-link
        Text(BodyM Short Link)
        padding 4px 0
        display inline-block
        color TextSecondary()
        +Media(Tablet, Mobile)
          display block

  .items-group-contacts
    +Media(Tablet, Mobile)
      margin-top 24px
      width 100%
      display flex
      flex-direction column
      align-items center
      justify-content center
    .contacts-links-title
      Text(BodyM Medium Short)
      margin-bottom 14px
      color TextPrimary()
      +Media(Tablet, Mobile)
        Text(Regular)
        color TextSecondary()
    .contacts-links
      margin-bottom 30px
      gap 12px
      display flex
      +Media(DesktopMax)
        width 396px
      +Media(Desktop)
        width 348px
      +Media(Laptop)
        width 260px
      +Media(Tablet, Mobile)
        align-items center
        margin-bottom 24px
    .contacts-icon
      width 44px
      height 44px
        +Media(Tablet, Mobile)
          width 44px
          height 44px
    .contacts-phone-title
      Text(BodyM Short)
      margin-bottom 16px
      color TextSecondary()
      +Media(Tablet, Mobile)
        margin-bottom 8px
    .contacts-phone
      Text(BodyL Semibold Short)
      color TextPrimary()

  .items-group-terms
    margin-top 34px
    width 100%
    .terms-links
      display flex
      +Media(Tablet, Mobile)
        flex-direction column
        text-align center
    .terms-link
      Text(BodyM Short Link)
      color TextSecondary()
      &:not(:last-child)
        +Media(DesktopMax, Desktop, Laptop)
          margin-right 22px
        +Media(Tablet, Mobile)
          margin-bottom 18px

  .items-group-footer
    margin-top 24px
    padding 16px 0
    width 100%
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    border-top 1px solid Divider()
    +Media(Tablet, Mobile)
      margin-top 16px
      flex-direction column
    .footer-items
      display flex
    .footer-item
      Text(BodyS Short)
      color TextSecondary()
      +Media(Tablet, Mobile)
        margin-bottom 16px
    .kazanexpress
      Text(BodyS)
      color TextPrimary()
      display flex
      align-items center
      .kazanexpress-logo
        Text(BodyS Semibold)
        margin-left 4px

  .items-group-subscribe
    +Media(DesktopMax, Desktop)
      display flex
      justify-content flex-end
    +Media(Laptop)
      margin-top 40px
      margin-right 32px
      flex-grow 1
    +Media(Tablet, Mobile)
      margin-top 40px
      flex 100% 0 0
      flex-grow 1
      flex-wrap wrap
    .subscribe-item
      +Media(Desktop)
        width 346px
      +Media(DesktopMax)
        width 396px
      +Media(Tablet, Mobile)
        flex 100% 0 0
        margin-right 0
        flex-grow 1
    .subscribe-title
      Text(BodyM Uppercase Semibold)
      color Gray(L8)
      margin-bottom 20px
      +Media(Tablet, Mobile)
        text-align center
    .subscribe-description
      padding 4px 0
      +Media(Tablet, Mobile)
        text-align center
    .ui-input
      Input(Underline, Medium)
      margin-top 13px
      input
        padding-left 0
        padding-right 0
      .ui-icon
        cursor pointer
        path
          fill Gray(L8)
</style>
