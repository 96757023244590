<template lang="pug">
.ui-wrapper.ds-header-categories
  ul.categories
    li.category(
      v-for="category in preloadCategories"
      :key="category.id"
    )
      link-ui.category-link(v-if="!category.isPromo" :to="category | formatUrl") {{ category.title }}
      link-ui.category-link.promo(v-if="category.isPromo" :to="category.deepLink" target='_blank') {{ category.title }}
  client-only
    button-ui.more(v-show="sortedCategories.length" @click='handleMoreClick') Ещё
      icon-ui.more-icon(name="navigation/chevron-down-fill_sharp" size="16" slot="after")
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import { buildCategoryUrl } from '~/components/header/catalog/catalog-categories/catalog';
import { uri } from '~/apollo-api/client/http-link';
import { DevCategories, ProdCategories } from '~/utils/categories';

export default {
  components: {
    LinkUi,
    IconUi,
    ButtonUi,
  },
  filters: {
    formatUrl: buildCategoryUrl,
  },
  data() {
    return {
      preloadCategories: [],
    };
  },

  async fetch() {
    this.preloadCategories = uri.includes('prod') ? ProdCategories : DevCategories;
  },

  computed: {
    ...mapGetters('catalog', ['sortedCategories']),
    ...mapState('catalog', ['promoCategories']),
  },
  watch: {
    promoCategories: {
      deep: true,
      immediate: true,
      handler() {
        this.preloadCategories = [
          ...this.promoCategories.map((_) => ({ ..._, isPromo: true })),
          ...this.sortedCategories.map((_) => ({ ..._, isPromo: false })),
        ];
      },
    },
  },
  // mounted() {
  //   if (process.client) {
  //     this.preloadCategories = [
  //       ...this.promoCategories.map((_) => ({ ..._, isPromo: true })),
  //       ...this.sortedCategories.map((_) => ({ ..._, isPromo: false })),
  //     ];
  //   }
  // },
  methods: {
    handleMoreClick() {
      this.$emit('more-click');
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-header-categories
  Text(BodyM Short)
  padding-bottom 8px
  display flex
  .categories
    margin 0 -12px
    height 40px
    overflow hidden
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    white-space nowrap
  .category
    margin 0 12px
    position relative
    overflow hidden
    min-height 40px
    display flex
    align-items center
    &::after
      content ''
      width 100%
      height 2px
      text-align center
      background TextPrimary()
      opacity 0
      transform translateX(-100%)
      transition all 500ms ease, opacity 500ms ease
    &:hover::after
      opacity 1
      transform translateX(0)
      color black
  .category-link
    display inline-block
    padding 8px 0
    outline none
    &.router-link-active
      font-weight 600
    &.promo
      font-weight 600
  .more
    margin-left 24px
    padding 12px 0
  .more-icon
    margin-left 4px
</style>
