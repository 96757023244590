<template lang="pug">
.ds-cart-no-content
  .no-content-title В корзине пока нет товаров
  .no-content-content Присмотритесь к новинкам и подборкам на главной странице или воспользуйтесь поиском, если ищете что-то конкретное
  button-ui(@click.stop="$router.push({ name: 'home' })") Посмотреть подборки
  footer.no-content-footer(v-if="!authenticated")
    div #[link-ui(to="/signin") Войдите] в свой аккаунт, чтобы посмотреть товары, которые уже в корзине
</template>

<script>
import ButtonUi from '~/components/common/button';
import LinkUi from '~/components/common/link';
import { AuthenticatedMixin } from '~/mixins/authenticated';

export default {
  components: {
    ButtonUi,
    LinkUi,
  },
  mixins: [AuthenticatedMixin],
};
</script>

<style lang="stylus">
.ds-cart-no-content
  max-width 450px
  margin 42px auto
  text-align center
  position: fixed
  left: 50%;
  top: 50%;
  width: 100%
  padding: 0 16px
  transform: translate(-50%,-50%);
  z-index: 1
  .no-content-image
    width 100%
    +Media(Laptop, Desktop, DesktopMax)
      margin-bottom 32px
    +Media(Tablet, Mobile)
      margin-bottom 24px
  .no-content-title
    margin-bottom 16px
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  .no-content-content
    color: #8B8E99;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  .no-content-footer
    Text(BodyL Long)
    color Gray(D8)
  .ui-button
    Button(Large Primary)
    margin 28px 0px
    width: 100%
  .ui-link
    Text(Link)
    color inherit
    text-decoration underline
</style>
